import parser from 'accept-language-parser'
import cookie from 'js-cookie'
import nextCookie from 'next-cookies'

const acceptLanguages = ['en', 'fr']
const defaultLocale = acceptLanguages[0]

export const validateLocale = (locale) => {
  return acceptLanguages.includes(locale) ? locale : defaultLocale
}

const getLocale = (ctx) => {
  try {
    const cookieLocale = nextCookie(ctx).locale
    let locale = defaultLocale
    if (cookieLocale) {
      // check if user has set locale
      locale = validateLocale(cookieLocale)
    } else {
      // check if user has set locale
      const systemlocales = parser.parse(
        ctx.req.headers['accept-language'] || defaultLocale
      )
      const systemLocale = systemlocales[0].code
      locale = validateLocale(systemLocale)
      cookie.set('locale', locale, { expires: 365 })
    }
    return locale
  } catch (error) {
    console.error(error)
    return defaultLocale
  }
}

export default getLocale
